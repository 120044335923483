import request from '@/utils/request'
import qs from 'qs'

export function setPassword(data) {
  return request({
    url: '/work/shop/set_password',
    method: 'post',
    data,
  })
}

export function validatePassword(data) {
  return request({
    url: '/work/shop/login_setting',
    method: 'post',
    data,
  })
}

export function clearPassword() {
  return request({
    url: '/work/shop/logout_setting',
    method: 'get',
  })
}

export function getRoomList(data) {
  return request({
    url: '/work/room/order_room_list',
    method: 'post',
    data,
  })
}

export function getRoomListV2(data) {
  return request({
    url: '/work/room/v2/order_room_list',
    method: 'post',
    data,
  })
}

export function getRoomListV3(data) {
  return request({
    url: '/work/room/v3/order_room_list',
    method: 'post',
    data,
  })
}

//获取是否为节假日
export function getIsHoliday(data) {
  return request({
    url: "/work/holiday/is_holiday",
    method: "post",
    data
  })
}

export function orderRoom(data) {
  return request({
    url: '/work/room/order_room',
    method: 'post',
    data,
  })
}

export function editOrderRoom(data) {
  return request({
    url: '/work/room/edit_order',
    method: 'post',
    data,
  })
}

export function cancelOrderRoom(data) {
  return request({
    url: '/work/room/cancel_order',
    method: 'post',
    data,
  })
}
/*
 * 结算清台
 * */

export function settlement(data) {
  return request({
    url: '/work/room/check_out',
    method: 'post',
    data,
  })
}

/*
 * 占座
 * */

export function takeUpSeat(data) {
  return request({
    url: '/work/room/hold_seat_order_room',
    method: 'post',
    data,
  })
}

/*
 * 更换包间
 * */
export function exchangeRoom(data) {
  return request({
    url: '/work/room/exchange_room',
    method: 'post',
    data,
  })
}

export function changeRoom(data) {
  return request({
    url: '/work/room/change_room',
    method: 'post',
    data,
  })
}

export function sendSms(data) {
  return request({
    url: '/work/room/order/send_sms',
    method: 'post',
    data,
  })
}

export function clientArrive(data) {
  return request({
    url: '/work/room/clientArrive',
    method: 'post',
    data,
  })
}

// 打印接待单
export function orderPrint(data) {
  return request({
    url: '/work/printer/print',
    method: 'post',
    data,
  })
}

// 重置操作密码
export const shopResetPassword = (params) =>
  request.post('/work/shop/reset_password', params)

// 获取订单是否有变更
export const getOrderChange = () =>
  request.get('/work/room/orderChangeNotice')

// 设置订单变更已读
export const setOrderChange = (params) =>
  request.post('/work/room/orderChangeNotice', params)

//设置订金
export const setDeposit = (params) =>
  request.post('/work/deposit/setDeposit', params)

//删除定金
export const delDeposit = (params) =>
  request.post('/work/deposit/delDeposit', params)

// 获取连台订单
export const getRoomRelations = params =>
  request.post('/work/room/getRoomRelations', params)

// 获取当前店铺未来所有订单
export const getFutureOrders = params =>
  request.get(`/work/room/getFutureOrders?${qs.stringify(params)}`)